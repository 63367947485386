<template>
  <div class="modal fade" ref="mdlRegistroSolicitudes" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header text-white bg-info">
          <div class="modal-title text-center">
            <strong style="font-size:30px">Registro de articulo</strong>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="input-group">
                <span class="input-group-text">
                  Artículo
                </span>
                <input
                  type="text" class="form-control"
                  placeholder="Nombre del articulo"
                  v-model="articulo.nombre"
                  disabled readonly
                />
              </div>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Fecha de entrega</th>
                      <th>Hora de entrega</th>
                      <th>Cantidad Entregada</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(salida,index) in salidas" :key="`salida-${index+1}`">
                      <td>
                        {{formatoFecha(salida.created_at)}}
                      </td>
                      <td>
                        {{formatoHora(salida.created_at)}}
                      </td>
                      <td>
                        {{salida.cantidad}} {{salida.abreviatura_unidad_medida}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-12 text-center">
              <button type="button" class="btn btn-outline-success" data-bs-dismiss="modal">
                <i class="mdi mdi-check-bold"></i>
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
import SalidaSrv from '@/services/inventario/SalidaSrv.js'
import Modal from 'bootstrap/js/dist/modal'
import moment from 'moment'
export default {
  name: 'MdlRegistroSolicitudesArticulosFecha',

  data() {
    return {
      id_articulo: null,
      id_solicitud: null,
      salidas: [],
      articulo:{
        id: null,
        nombre: "",
        descripcion: ""
      }
    }
  },
  methods: {
    cargarArticulo() {
      let self = this

      let idArticulo = self.id_articulo

      ArticuloSrv.articuloJSON(idArticulo, {}).then(response => {
        self.articulo = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el artículo '+idArticulo
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cargaSalidaPorArticulo() {
      var self = this

      self.salidas = []

      let params = {
        orden: 'desc',
        cantidad: 10,
        id_solicitud_articulos: self.id_solicitud
      }

      SalidaSrv.salidaPorArticuloJson(self.id_articulo, params).then(response => {
        self.salidas = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar las salida del articulo ' + self.id_articulo
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },

    formatoHora(mt) {
      return moment(mt, 'HH:mm:ss').format('HH:mm')
    },
    mostrar(datos) {
      var self = this
      
      self.id_articulo = datos.id_articulo
      self.id_solicitud = datos.id_solicitud

      self.cargarArticulo()
      self.cargaSalidaPorArticulo()

      var modal = new Modal(this.$refs.mdlRegistroSolicitudes)
      modal.show()
    }
  }
}
</script>

<style scoped>

</style>