<template>
  <Layout :tituloPagina="`Inventario | Solicitudes | ${modo == 'nueva' ? 'Nueva':'Visor'}`">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{modo == 'nueva' ? 'Nueva':'Visor'}} solicitud
            </h4>
            <div>
              <a href="#" onclick="return false;" @click="cerrar()">
                <i class="mdi mdi-close text-dark"></i>
              </a>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-2" v-show="modo == 'visor'">
                <label>Id</label>
                <input
                  v-show="modo == 'visor'"
                  placeholder="#ID"
                  class="form-control"
                  v-model="solicitud.id"
                  disabled
                />
              </div>
              <div class="col-md-4">
                <label>Nombre del solicitante</label>
                <input 
                  v-show="modo == 'nueva'"
                  type="text"
                  class="form-control"
                  placeholder="Nombre del solicitante"
                  :value="
                    usuario.nombre+' '+
                    usuario.apellido_paterno+' '+
                    usuario.apellido_materno
                  "
                  disabled
                />
                <input 
                  v-show="modo == 'visor'"
                  class="form-control"
                  placeholder="Nombre del solicitante"
                  v-model="solicitud.nombre_solicitante"
                  disabled/>
              </div>
              <div class="col-md-3">
                <label>Fecha de solicitud</label>
                <input 
                  v-show="modo == 'nueva'"
                  type="text"
                  class="form-control"
                  placeholder="DD/MM/YYYY"
                  v-model="fecha_inicial"
                  disabled/>
                <input 
                  v-show="modo == 'visor'"
                  type="text"
                  class="form-control"
                  style="background:#57C9EB; color: #fff; border-color:#57C9EB"
                  :value="formatoFecha(solicitud.created_at)"
                  disabled
                />
              </div>
              <div class="col-md-3" v-show="modo == 'visor'">
                <label>Estado</label>
                <br>
                <div
                  class="p-2 text-center rounded text-white"
                  :class="{
                    'bg-info':solicitud.estado==1,
                    'bg-warning':solicitud.estado==2,
                    'bg-primary':solicitud.estado==3,
                    'bg-danger':solicitud.estado==4
                  }">
                  <span style="font-size:16px">
                    <strong>
                      {{solicitud.estado==1?'Pendiente':''}}
                      {{solicitud.estado==2?'En proceso':''}}
                      {{solicitud.estado==3?'Cerrada':''}}
                      {{solicitud.estado==4?'Cancelado':''}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <br>

            <div class="row">
              <div class="col-md-6">
                <label for="txtNotaSol">Nota del solicitante</label>
                <textarea
                  id="txtNotaSol" class="form-control" 
                  v-model="solicitud.nota"
                  maxlength="2000" rows="4"
                  :readonly="modo=='visor'"
                  placeholder="Nota de interna del solicitante"
                ></textarea>
              </div>
              <div
                class="col-md-6"
                v-if="
                  modo == 'visor' &&
                  solicitud.estado != 3 &&
                  solicitud.estado != 4 &&
                  tienePermiso('cmpNotaAlmacenista', 'componente')
                "
              >
                <label for="txtNotaAlm">Nota del almacenista</label>
                <textarea
                  id="txtNotaAlm" class="form-control" 
                  v-model="nota_almacenista"
                  maxlength="2000" rows="4"
                  placeholder="Nota del almacenista"
                ></textarea>
              </div>
            </div>

            <div class="row" v-if="solicitud.ids_salidas.length != 0">
              <br>
              <div class="offset-md-6 col-md-6 text-right">
                <strong>Salidas:</strong>
                <router-link
                  :to="{ name: 'visorSalidaAlmacen', params: { id: id_salida } }"
                  v-for="id_salida in solicitud.ids_salidas"
                  :key="id_salida"
                >
                  {{id_salida}}
                </router-link>
              </div>
            </div>

            <br>

            <div class="text-right">
              <button class="btn btn-success btn-sm" @click="mostrarMdlNuevaPartida()"
                v-show="modo=='nueva'">
                <i class="mdi mdi-plus-thick"></i>
                Agregar artículo
              </button>
            </div>

            <div class="row">
              <div class="col-md-12">
                <label v-show="modo == 'visor'">Artículos solicitados</label>
                <div class="table-responsive">
                  <table class="table table-hover table-nowrap" style="min-width: 1200px">
                    <thead>
                      <tr>
                        <th style="width:40px"></th>
                        <th style="width: 80px">No.</th>
                        <th style="width:85px">ID articulo</th>
                        <th style="min-width: 200px">Nombre del artículo</th>
                        <th style="width:100px">Familia de artículo</th>
                        <th>División</th>
                        <th style="width: 90px">Cantidad {{modo=='nueva'?'':'solicitada'}}</th>
                        <th>Unidad de medida</th>
                        <th v-show="modo!='nueva'">Cantidad entregada</th>
                        <th v-show="modo!='nueva'">Cantidad pendiente</th>
                        <th
                          v-show="
                            modo!='nueva' &&
                            solicitud.estado != 4 &&
                            solicitud.estado != 3 &&
                            tienePermiso('cmpStockTotalSolicitud', 'componente')
                          "
                          title="Cantidad disponible en almacén">Stock total</th>
                        <th v-show="modo!='nueva'" style="width:120px" 
                          v-if="tienePermiso('cmpCantidadEntregarSolicitud', 'componente')">
                          Cantidad a entregar
                        </th>
                        <th class="text-center" style="width: 225px">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(partida,index) in solicitud.partidas">
                        <tr v-if="true" :key="`partida-${index+1}`">
                          <td>
                            <button
                              v-show="partida.id_articulo_original != null"
                              class="btn btn-soft-dark btn-sm"
                              @click="palancaMostrarArticuloOriginal(partida)"
                              title="Artículo remplazado"
                            >
                              <i
                                class="mdi mdi-chevron-down"
                                v-show="partida.mostrar_articulo_original == false"
                              ></i>
                              <i
                                class="mdi mdi-chevron-up"
                                v-show="partida.mostrar_articulo_original == true"
                              ></i>
                            </button>
                          </td>
                          <td>{{index+1}}</td>
                          <td class="fw-semibold">{{partida.articulo.id}}</td>
                          <td>
                            <div style="min-height: 30px; display: inline-block">
                              <a
                                href="#"
                                onclick="return false;"
                                @click="mostarGaleriaFotosArticulo(partida.articulo.id)"
                                v-if="partida.articulo.foto_principal"
                              >
                                <img
                                  :src="`${API}/articulos/${partida.articulo.id}/fotos/principal?tipo_foto=64x64&tmp=${Math.random().toFixed(10).substring(2)}&_tk=${tk}`"
                                  style="width:30px; height:30px;"
                                  class="icon-articulo"
                                />
                              </a>
                              <img
                                :src="inventarioIcon"
                                style="width:30px; height:30px;"
                                v-if="!partida.articulo.foto_principal"
                              />
                            </div>
                            {{partida.articulo.nombre}}
                          </td>
                          <td>{{partida.articulo.familia.familia}}</td>
                          <td>{{partida.articulo.familia.division}}</td>
                          <td class="text-right">{{partida.cantidad_solicitada}}</td>
                          <td>{{partida.abreviatura_unidad_medida}}</td>
                          <td class="text-right" v-show="modo!='nueva'">{{partida.cantidad_entregada}}</td>
                          <td class="text-right" v-show="modo!='nueva'">{{partida.cantidad_pendiente}}</td>
                          <td
                            class="text-right"
                            v-show="
                              modo!='nueva' &&
                              solicitud.estado != 4 &&
                              solicitud.estado != 3 &&
                              tienePermiso('cmpStockTotalSolicitud', 'componente')
                            "
                          >
                            {{partida.stock_total}}
                          </td>
                          <td
                            v-if="modo!='nueva' && tienePermiso('cmpCantidadEntregarSolicitud', 'componente')"
                          >
                            <input
                              type="number"
                              v-model="partida.cantidad_por_entregar"
                              :disabled='(solicitud.estado == 4 || solicitud.estado == 3) || (partida.cantidad_solicitada-partida.cantidad_entregada == 0)'
                              class="form-control text-right"
                              ref="cantidadPorEntregar"
                              min="0"
                              onclick="this.select()"
                              oninput="this.value = Math.abs(this.value)"
                            />
                          </td>
                          <td class="text-center">
                            <button
                              :disabled="(partida.cantidad_entregada == 0)"
                              v-show="modo!='nueva'"
                              class="btn btn-info btn-sm"
                              @click="mostrarRegistroFecha(partida.articulo.id, solicitud.id)"
                            >
                              <i class="mdi mdi-history"></i>
                              Registro
                            </button>
                            <button class="btn btn-danger btn-sm"
                              v-show="modo=='nueva' && solicitud.estado == 1"
                              @click="eliminarPartida(index)">
                              <i class="mdi mdi-trash-can-outline"></i>
                              Eliminar
                            </button>
                            <button
                              class="btn btn-success btn-sm"
                              v-show="modo != 'nueva' && (solicitud.estado == 1 || solicitud.estado == 2)"
                              :disabled="partida.id_articulo_original != null"
                              @click="mostrarMdlRemplazarPartida(partida.id)"
                            >
                              <i class="mdi mdi-refresh"></i>
                              Remplazar
                            </button>
                          </td>
                        </tr>
                        <tr v-if="partida.mostrar_articulo_original" :key="'partida_articulo-original-'+partida.id"
                          style="color: orangered">
                          <td colspan="2" class="text-center">
                            <small>Solicitud original</small>
                          </td>
                          <td>{{partida.articulo_original.id}}</td>
                          <td>
                            <div style="min-height: 30px; display: inline-block">
                              <a
                                href="#"
                                onclick="return false;"
                                @click="mostarGaleriaFotosArticulo(partida.articulo_original.id)"
                                v-if="partida.articulo_original.foto_principal"
                              >
                                <img
                                  :src="`${API}/articulos/${partida.articulo_original.id}/fotos/principal?tipo_foto=64x64&tmp=${Math.random().toFixed(10).substring(2)}&_tk=${tk}`"
                                  style="width:30px; height:30px;"
                                  class="icon-articulo"
                                />
                              </a>

                              <img
                                :src="inventarioIcon"
                                style="width:30px; height:30px;"
                                v-if="!partida.articulo_original.foto_principal"
                              />
                            </div>
                            {{partida.articulo_original.nombre}}
                          </td>
                          <td>{{partida.articulo_original.familia.familia}}</td>
                          <td>{{partida.articulo_original.familia.division}}</td>
                          <td class="text-right">{{partida.cantidad_solicitada_original}}</td>
                          <td colspan="10">{{partida.articulo_original.abreviatura_unidad_medida}}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <div class="btn-group">
            <button @click="cerrar()" class="btn btn-secondary">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="guardar()"
              v-show="modo == 'nueva'"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-check-bold' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              Enviar solicitud
            </button>
            <button
              class="btn btn-soft-dark"
              @click="cerrarSolicitud()"
              v-if="modo=='visor' && (solicitud.estado == 1 || solicitud.estado == 2)"
            >
              <i class="mdi mdi-lock"></i>
              Cerrar solicitud
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="darSalida()"
              v-if="modo == 'visor' && tienePermiso('cmpCantidadEntregarSolicitud', 'componente')"
              :disabled='solicitud.estado == 4 || solicitud.estado == 3 || bandera_spinner'
            >
              <i 
                class="mdi"
                :class="!bandera_spinner ? 'mdi-check-bold' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              Dar salida
            </button>
          </div>
        </div>
      </div>
    </div>

    <mdl-registro-solicitudes-articulos-fecha ref="MdlRegistro"/>
    <MdlNuevaPartida
      ref="mdlNuevaPartida"
      v-on:partida:lista="agregarPartida($event)"
      v-on:partida:remplazar="remplazarPartida($event)"
    />
    <cmp-galeria-fotos 
      ref="galeria_solicitud_articulo"
      :nombreGaleria="`galeria_solicitud_articulo`"
    />
    <br>
  </Layout>
</template>

<script>
import MdlNuevaPartida from './MdlNuevaPartida.vue'
import SistemaSrv from '@/services/SistemaSrv.js'
import SolicitudArticulosSrv from '@/services/inventario/SolicitudArticulosSrv.js'
import MdlRegistroSolicitudesArticulosFecha from './MdlRegistroSolicitudesArticulosFecha.vue'
import inventarioIcon from '@/assets/img/inventario/inventario.png'
import CmpGaleriaFotos from "../CmpGaleriaFotos.vue"
import moment from 'moment'
import { APIINV as API } from '@/API.js'
import { todoGetters } from "@/state/helpers"
import Layout from '@/views/layouts/main'
import Swal from 'sweetalert2'
export default {
  name: 'EdicionSolicitudArticulo',
  components: {
    Layout,
    MdlRegistroSolicitudesArticulosFecha,
    MdlNuevaPartida,
    CmpGaleriaFotos
  },
  data() {
    return {
      modo: 'nueva',
      API : API,
      tk: localStorage.getItem('argusblack.token'),
      inventarioIcon: inventarioIcon,
      
      solicitud: {
        id_solicitante: null,
        nota: '',
        partidas:[],
        estado: 1,
        ids_salidas: []
      },
      nota_almacenista: "",

      fecha_inicial:'',
      usuario: {},
      partida_remplazar: {},
      bandera_spinner: false
    }
  },
  created() {
    var self = this

    // Determinación del modo
    if (this.$route.path.indexOf('solicitudes/nueva') != -1) this.modo = 'nueva'
    else this.modo = 'visor'

    if (this.modo == 'visor') this.cargarSolicitudArticulo()

    //Carga de la fecha inicial del sistema
    if (self.fecha_inicial == '' && this.modo == 'nueva') {
      SistemaSrv.fecha({formato: 'd/m/Y'}).then(response => {
        self.fecha_inicial= response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    }

    if (this.modo == 'nueva') {
      self.usuario= self.$store.state.todo.usuario
    }
  },
  computed:{
    ...todoGetters
  },
  methods: {
    cerrarSolicitud() {
      let self = this
      let idSolicitud = self.$route.params.id

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Cierre de solicitud",
        html: '¿Estás seguro que deseas cerrar la solicitud de artículos? <br><br>' + 
          'Cerrar una solicitud impide que puedas a hacer algún movimiento de salida sobre algún artículo solicitado.',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          SolicitudArticulosSrv.cerrar(idSolicitud, self.solicitud).then(response => {
            swal.fire("Cerrada!", "Se ha cerrado correctamente la solicitud", "success");
            self.solicitud.estado = 3
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo cerra la solicitud'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    darSalida() {
      var self = this

      var solicitud = self.solicitud

      self.bandera_spinner = true

      if( !self.verificarCantidades() ) return;

      var solicitud = {
        nota_almacenista: self.nota_almacenista,
        partidas: []
      }

      self.solicitud.partidas.forEach(partida => {
        // Si la cantidad por entregar es 0, se evita continuar
        if(partida.cantidad_por_entregar == 0) return

        // Si la cantidad es mayor que 0 se agrega a la lista de partidas de la solicitud a 
        // dar salida
        solicitud.partidas.push({
          id_partida_solart: partida.id,
          cantidad_por_entregar: partida.cantidad_por_entregar
        })
      })

      SolicitudArticulosSrv.darSalida(self.solicitud.id,solicitud).then(response => {
        iu.msg.success('Se entregaron correctamente las cantidades')
        self.cargarSolicitudArticulo()
      }).catch(error => {
        let mensaje
        let articulos_insuficiente = []
        try {
          mensaje = error.response.data.message
          articulos_insuficiente = error.response.data.articulos_con_cantidad_insuficiente || []
        } catch (e) {
          mensaje = 'No se pudo entregar las cantidades correctamente'
          articulos_insuficiente = []
        }

        if(articulos_insuficiente.length != 0){
          mensaje += '<ul>'
          articulos_insuficiente.forEach(art =>{
            mensaje += '<li>' + art.nombre_articulo + '</li>'
          })
          mensaje += '</ul>'
        }

        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },

    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },

    agregarPartida(nuevaPartida) {
      var self = this

      let index = self.solicitud.partidas.findIndex(function (partida) {
        return partida.articulo.id == nuevaPartida.articulo.id
      })
      
      if(index != -1) {
        iu.msg.warning('No se puede registrar una partida con el mismo artículo')
        return
      }

      self.solicitud.partidas.push(nuevaPartida)
    },

    atras() {
      this.$router.go(-1)
    },
    
    cargarSolicitudArticulo() {
      let self = this

      let idSolicitud = self.$route.params.id

      SolicitudArticulosSrv.solicitudJSON(idSolicitud, { con_ids_salidas: true }).then(response => {
        let solicitud = response.data
        
        solicitud.partidas.map(partida => {
          partida.cantidad_por_entregar = 0
          partida.cantidad_pendiente = partida.cantidad_solicitada - partida.cantidad_entregada
          partida.mostrar_articulo_original = false

          return partida
        })

        self.solicitud = solicitud
        
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la solicitud de artículos '+ idSolicitud
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar() {
      this.atras()
    },

    eliminarPartida(index) {
      var self = this;
      // Hay cargas suficientes
      self.solicitud.partidas.splice(index,1);
    },

    guardar() {
      var self = this

      self.bandera_spinner = true

      if(self.solicitud.partidas.length == 0) {
        iu.msg.warning('No se puede realizar la solicitud de artículos, ya que no se ha seleccionado ningún artículo')
        self.bandera_spinner = false
        return
      }

      let newSolicitud = Object.assign({
        estado: self.solicitud.estado,
        id_solicitante: self.usuario.id,
        nota: self.solicitud.nota,
        partidas: []
      })

      self.solicitud.partidas.forEach(partida => {
        let newPartida = Object.assign({
          id_articulo: partida.articulo.id,
          nombre_articulo: partida.articulo.nombre,
          unidad_medida: partida.abreviatura_unidad_medida,
          cantidad_solicitada: partida.cantidad_solicitada
        })

        newSolicitud.partidas.push(newPartida)
      })
      
      SolicitudArticulosSrv.guardar(newSolicitud).then(response=>{
        iu.msg.success('Se ha enviado correctamente la solicitud de Artículos')
        self.atras()
      }).catch(error=>{
        let mensaje
        try{
          mensaje = error.response.data.message
        }catch(e){
          mensaje = 'No se pudo guardar la solicitud de Artículos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },

    mostarGaleriaFotosArticulo: function(idArticulo) {
      var self = this
      self.$refs.galeria_solicitud_articulo.mostrarGaleria(idArticulo)
    },

    mostrarMdlNuevaPartida() {
      this.$refs.mdlNuevaPartida.mostrar(false)
    },

    mostrarMdlRemplazarPartida(idPartida) {
      this.$refs.mdlNuevaPartida.mostrar(true, idPartida)
    },

    mostrarRegistroFecha(id_articulo, id_solicitud) {
      var self = this
      
      let datos = {
        id_articulo: id_articulo,
        id_solicitud: id_solicitud
      }

      self.$refs.MdlRegistro.mostrar(datos)
    },

    palancaMostrarArticuloOriginal: function(partida) {
      if (partida.mostrar_articulo_original) partida.mostrar_articulo_original = false
      else partida.mostrar_articulo_original = true
    },

    remplazarPartida(datos){
      var self = this

      let index = self.solicitud.partidas.findIndex(function (partida) {
        return partida.articulo.id == datos.nuevaPartida.articulo.id
      })
      
      if(index != -1) {
        iu.msg.warning('No se puede remplazar una partida con el mismo artículo')
        return
      }

      SolicitudArticulosSrv.partidaJSON(self.solicitud.id,datos.id_partida).then(response => {
        self.partida_remplazar = response.data

        let newPartida = {
          id: self.partida_remplazar.id,
          id_articulo: datos.nuevaPartida.articulo.id,
          cantidad_solicitada: datos.nuevaPartida.cantidad_solicitada
        }
        
        SolicitudArticulosSrv.actualizarPartida(self.solicitud.id, newPartida).then(response => {
          iu.msg.success('Se remplazo correctamente la partida de la solicitud de articulos')
          this.cargarSolicitudArticulo()
        }).catch(error=>{
          let mensaje
          try{
            mensaje = error.response.data.message
          }catch(e){
            mensaje = 'No se pudo guardar la partida de la solicitud de Artículos'
          }
          iu.msg.error(mensaje)
          console.log(error)
        })
      }).catch(error=>{
        let mensaje
        try{
          mensaje = error.response.data.message
        }catch(e){
          mensaje = 'No se pudo cargar la partida de la solicitud'
        }
        iu.msg.error(mensaje)
        console.log(error)
        return
      })
    },

    verificarCantidades(){
      let self = this
      var ok = true
      
      self.solicitud.partidas.forEach((partida,index) => {
        let cantidad_solicitada = partida.cantidad_solicitada
        let cantidad_entregada = partida.cantidad_entregada
        let cantidad_por_entregar = partida.cantidad_por_entregar

        let cantidad_restante = cantidad_solicitada - cantidad_entregada

        if(cantidad_por_entregar > cantidad_restante){
          iu.msg.warning('La cantidad ingresada es mayor a la cantidad solicitada vuelva a ingresar la cantidad correcta')
          let input = self.$refs["cantidadPorEntregar"][index]
          input.select();
          self.bandera_spinner = false
          ok= false
          return false
        }

        if(cantidad_por_entregar < 0){
          iu.msg.warning('No se permiten cantidades menores a 0')
          let input = self.$refs["cantidadPorEntregar"][index]
          input.select();
          self.bandera_spinner = false
          ok= false
          return false
        }

        // if(cantidad_por_entregar % 1 != 0){
        //   iu.msg.warning('No se permiten números decimales')
        //   let input = self.$refs["cantidadPorEntregar"][index]
        //   input.select();
        //   self.bandera_spinner = false
        //   ok= false
        //   return false
        // }
      })
      
      if(ok == true) return true
      else {
        self.bandera_spinner = false
        return false
      }
    }
  }
}
</script>

<style scoped>
.table-hover > tbody > tr.sin-hover:hover {
  background-color: #1C84C6;
}
.icon-articulo {
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
</style>