<template>
  <div class="modal fade" ref="mdlNuevaPartida" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{!remplazar ? 'Nueva' : 'Remplazar'}} partida
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="bArticuloSeleccionado == false" style="min-height: 350px;">
            <div class="row">
              <div class="col-md-6">
                <label for="dato">Dato</label>
                <filtrador
                  v-on:dato-actualizado="
                    dato = $event,
                    refrescarArticulos()
                  "
                  :datoini="dato"
                  placeholder="Dato del artículo"
                ></filtrador>
              </div>
              <div class="col-md-3">
                <label>División</label>
                <select
                  class="form-select"
                  v-model="id_familia"
                  @change="cargarFamiliaSeleccionada();"
                  name="division"
                >
                  <option :value="null">Cualquiera</option>
                  <option v-for="familia in familias" :key="familia.id" :value="familia.id">{{familia.division}}</option>
                </select>
              </div>
              <div class="col-md-3">
                <label>Familia</label>
                <input
                  type="text" class="form-control"
                  placeholder="Familia"
                  disabled v-model="familia_seleccionada.familia"
                />
              </div>
            </div>
            <br>
            <div class="table-responsive">
              <table id="tabla-articulos" class="table table-hover table-nowrap">
                <thead>
                  <tr>
                    <th style="width:46px"></th>
                    <th>ID</th>
                    <th style="width:300px">Nombre</th>
                    <th>Cantidad</th>
                    <th>División</th>
                    <th>Familia</th>
                    <th style="width: 65px">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="articulo in articulos" :key="`art-${articulo.id}`">
                    <td>
                      <div style="min-height: 30px; display: inline-block">
                        <a
                          href="#"
                          onclick="return false;"
                          @click="mostarGaleriaFotosArticulo(articulo.id)"
                          v-if="articulo.foto_principal"
                        >
                          <img
                            :src="`${API}/articulos/${articulo.id}/fotos/principal?tipo_foto=64x64&tmp=${Math.random().toFixed(10).substring(2)}&_tk=${tk}`"
                            style="width:30px; height:30px;"
                            class="icon-articulo"
                            v-if="articulo.foto_principal"
                          />
                        </a>
                        <img
                          :src="inventarioIcon"
                          style="width:30px; height:30px;"
                          v-if="!articulo.foto_principal"
                        />
                      </div>
                    </td>
                    <td>{{articulo.id}}</td>
                    <td>{{articulo.nombre}}</td>
                    <td>{{articulo.stock_total}} {{articulo.unidad_medida.abreviatura}}</td>
                    <td>{{articulo.familia ? articulo.familia.division : ''}}</td>
                    <td>{{articulo.familia ? articulo.familia.familia : ''}}</td>
                    <td>
                      <button @click="seleccionarArticulo(articulo)"
                        class="btn btn-success btn-sm">
                        Seleccionar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-center" v-if="ta >= 10">
              <paginador
                :pag="page"
                :totpag="totpag"
                :epp="epp"
                @cargar-pagina="cargarPagina"
                @epp-actualizado="
                  epp = $event,
                  refrescarArticulos()
                "
              ></paginador>
            </div>
          </div>
          <div v-if="bArticuloSeleccionado == true">
            <div class="row">
              <div class="col-md-4">
                <div class="divFotoArticulo">
                  <a
                    href="#"
                    onclick="return false;"
                    @click="mostarGaleriaFotosArticulo(articulo_seleccionado.id)"
                    v-if="articulo_seleccionado.foto_principal"
                  >
                    <img
                      :src="`${API}/articulos/${articulo_seleccionado.id}/fotos/principal?tipo_foto=256x256&tmp=${Math.random().toFixed(10).substring(2)}&_tk=${tk}`"
                      v-if="articulo_seleccionado.foto_principal"
                      class="img-thumbnail"
                    />
                  </a>
                  <img
                    :src="inventarioIcon"
                    v-if="!articulo_seleccionado.foto_principal"
                    class="img-thumbnail"
                  />
                </div>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-12">
                    <label>Nombre del artículo</label>
                    <div class="input-group">
                      <input 
                        type="text" v-model="articulo_seleccionado.nombre"
                        class="form-control" readonly
                        placeholder="Nombre del articulo seleccionado"
                      />
                      <button
                        class="btn btn-success"
                        @click="bArticuloSeleccionado=false"
                        title="Cambiar artículo"
                      >
                        <i class="mdi mdi-refresh"></i>
                        Cambiar
                      </button>
                    </div>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-md-12">
                    <label>Descripción del artículo</label>
                    <textarea
                      class="form-control" name="descripcion_articulo"
                      rows="4" v-model="articulo_seleccionado.descripcion"
                      disabled placeholder="Descripcion del articulo seleccionado"
                    ></textarea>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-md-4">
                    <label>Cantidad solicitada</label>
                    <input
                      type="number"
                      ref="cantidadSolicitada"
                      class="form-control text-right"
                      placeholder="0"
                      v-model="nuevaPartida.cantidad_solicitada"
                      :disabled="nuevaPartida.articulo.id == null"
                      onclick="this.select()"
                      min="0"
                      oninput="this.value = Math.abs(this.value)"
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Unidad de medida</label>
                    <span class="form-control">
                      {{articulo_seleccionado.unidad_medida.abreviatura}}
                    </span>
                    <small class="text-danger" v-show="articulo_seleccionado.id_unidad_medida == null">
                      * Se necesita registrar una unidad de medida al artículo, 
                      da click 
                      <a @click="editarArticulo(articulo_seleccionado.id)">
                        aquí
                      </a>
                      para agregarla
                    </small>
                  </div>

                  <div class="col-md-4" v-show="articulo_seleccionado.id_unidad_medida != null">
                    <label>Stock en almacén</label>
                    <div class="input-group">
                      <input
                        type="number" name="cantidades"
                        class="form-control text-center"
                        :value="articulo_seleccionado.stock_total"
                        disabled readonly placeholder="0"
                      />
                      <span class="input-group-text">
                        {{articulo_seleccionado.unidad_medida.nombre}}{{articulo_seleccionado.stock_total > 1 ? 's': '' }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            <i class="mdi mdi-close"></i>
            Cancelar
          </button>
          <button type="button" class="btn btn-success" @click="agregar()" v-if="bArticuloSeleccionado == true">
            <i class="mdi" :class="!remplazar ? 'mdi-plus-thick' : 'mdi-refresh'"></i>
            {{!remplazar ? 'Agregar' : 'Remplazar'}}
          </button>
        </div>
        <cmp-galeria-fotos 
          ref="galeria_solicitud_articulo"
          :nombreGaleria="`galeria-nueva-solicitud-articulo`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FamiliaArticulosSrv from '@/services/inventario/FamiliaArticulosSrv.js'
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
import inventarioIcon from '@/assets/img/inventario/inventario.png'
import Filtrador from '@/components/Filtrador.vue'
import Paginador from '@/components/Paginador.vue'
import CmpGaleriaFotos from "../CmpGaleriaFotos.vue"
import {APIINV as API} from '@/API.js'
import Modal from 'bootstrap/js/dist/modal';

export default {
  name: 'MdlNuevaPartida',
  components: {
    Filtrador,
    Paginador,
    CmpGaleriaFotos
  },
  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      inventarioIcon: inventarioIcon,
      bArticuloSeleccionado: false,

      remplazar: false,
      id_partida: null,

      dato: '',
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.mdlNuevaPartida.por_pagina') || 10),
      ta: 0, // Total de Articulos
      totpag: 0, // Total de páginas

      articulos:[],
      familias: [],

      id_familia: null,

      articulo_seleccionado: {
        id: null,
        id_familia: null,
        nombre: '',
        descripcion: '',
        marca: '',
        modelo: '',
        sw_stock_minimo: 0,
        stock_total: 0,
        id_unidad_medida: null,
        unidad_medida: {
          nombre: '',
          abreviatura: ''
        },
        foto_principal: null
      },

      familia_seleccionada:{
        familia: null,
        division: '',
        descripcion: ''
      },

      nuevaPartida: {
        articulo: {
          id: null
        },
        cantidad_solicitada: 0,
        cantidad_entregada: 0,
        abreviatura_unidad_medida: ''
      }
    }
  },

  created() {
    let self = this
    self.cargarFamilias()
  },

  methods: {
    agregar() {
      let self = this

      let nuevaPartida = Object.assign({}, self.nuevaPartida)

      // Obtención de la abreviatura de la unidad de medida
      let unidadMedida = self.articulo_seleccionado.unidad_medida

      if(unidadMedida == undefined) {
        iu.msg.warning('Es necesario seleccionar una unidad de medida')
        let select = self.$refs.unidadMedida
        select.focus();
        return
      }

      if(nuevaPartida.cantidad_solicitada == 0){
        iu.msg.warning('La cantidad no puede ser 0')
        let input = self.$refs.cantidadSolicitada
        input.select();
        return
      }

      let abreviaturaUnidadMedida = unidadMedida.abreviatura

      Object.assign(nuevaPartida, {
        articulo: Object.assign({}, self.articulo_seleccionado),
        abreviatura_unidad_medida: abreviaturaUnidadMedida
      })
      
      if(!self.remplazar) this.$emit('partida:lista', nuevaPartida)
      else {
        let datos = {
          id_partida: self.id_partida,
          nuevaPartida: nuevaPartida
        }
        self.$emit("partida:remplazar", datos)
      }

      var modal = Modal.getInstance(this.$refs.mdlNuevaPartida)
      modal.hide()
      this.limpiarFormulario()
    },

    cargarArticulo() {
      let self = this

      let idArticulo = self.nuevaPartida.articulo.id
      let params = {
        con_unidad_medida: true,
        con_familia: true,
      }

      ArticuloSrv.articuloJSON(idArticulo, params).then(response => {
        self.articulo_seleccionado = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el artículo '+idArticulo
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarFamilias() {
      var self = this

      let params = {
        sin_paginacion: true,
        solo_idfamdiv:true
      }

      FamiliaArticulosSrv.familiasJSON(params).then(response => {
        let page = response.data
        self.familias = page
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las familias de artículos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarFamiliaSeleccionada() {
      let self = this

      let idFamilia = self.id_familia

      iu.spinner.mostrar('#tabla-articulos')

      // Si el ID de la familia es null indica que no se ha seleccionado, 
      // entonces se evita continuar y se limpia la partida
      if(idFamilia == null) {
        self.limpiarFormulario()
        self.refrescarArticulos()
        return
      }

      self.articulo_seleccionado.foto_principal = null

      FamiliaArticulosSrv.familiaJSON(idFamilia).then(response => {
        self.familia_seleccionada = response.data
        self.refrescarArticulos()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la familia de artículos '+ idFamilia
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarArticulos({ page: n })
    },

    editarArticulo(idArticulo) {
      var modal = Modal.getInstance(this.$refs.mdlNuevaPartida)
      modal.hide()
      this.$router.push({name: 'edicionArticulo', params: {id: idArticulo}})
    },

    refrescarArticulos() {
      let self = this
      
      iu.spinner.mostrar('#tabla-articulos')
      
      let params = {
        dato: self.dato,
        id_familia: self.id_familia,
        page: self.page,
        epp: self.epp,
        orden: 'desc',
        con_familia: true,
        con_unidad_medida: true,
      }

      ArticuloSrv.articulosJSON(params).then(response => {
        localStorage.setItem('argusblack.mdlNuevaPartida.por_pagina', self.epp)
        let page = response.data
        self.articulos = page.data
        self.ta = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarArticulos()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los artículos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#tabla-articulos')
      })
    },

    limpiarFormulario() {
      let self = this

      self.id_familia = null

      Object.assign(self.nuevaPartida.articulo, {
        id: null
      })

      Object.assign(self.nuevaPartida, {
        cantidad_solicitada: 0
      })

      self.articulos=[]
      self.familias= []
      self.familia_seleccionada={}

      Object.assign(self.articulo_seleccionado, {
        id: null,
        id_familia: null,
        nombre: '',
        descripcion: '',
        marca: '',
        modelo: '',
        sw_stock_minimo: 0,
        stock_total: 0,
        id_unidad_medida: null,
        unidad_medida: {
          nombre: '',
          abreviatura: ''
        },
        foto_principal: null
      })

      self.bArticuloSeleccionado = false
      self.cargarFamilias()
    },

    seleccionarArticulo(articulo) {
      let self = this

      Object.assign(self.articulo_seleccionado, articulo)

      Object.assign(self.nuevaPartida, {
        id_familia: articulo.id_familia,
        articulo: articulo,
        cantidad: 0,
        id_partida_reqart: null,
        division: articulo.familia.division,
        familia: articulo.familia.familia,
        abreviatura_unidad_medida: ''
      })

      self.bArticuloSeleccionado = true
    },

    mostrar(remplazar = false, idPartida = null) {
      var self = this

      self.remplazar = remplazar
      self.id_partida = idPartida

      var modal = new Modal(this.$refs.mdlNuevaPartida)
      modal.show()
    },

    mostarGaleriaFotosArticulo: function(idArticulo) {
      var self = this
      self.$refs.galeria_solicitud_articulo.mostrarGaleria(idArticulo)
    }
  }
}
</script>

<style scoped>
.divFotoArticulo {
  width:100%;
  height: 100%;
  text-align: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.divFotoArticulo img {
  width: 100%;
}
.icon-articulo {
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
</style>